import { createContext, useContext, useState } from 'react';

const defaultValue: NavContextType = {
  leftNavState: 'expanded',
  navItem: '',
  setNavItem: () => undefined,
  setLeftNavState: () => undefined,
};

type LeftNavState = 'hidden' | 'collapsed' | 'expanded';
type NavItem = string | undefined;

interface NavContextType {
  leftNavState: LeftNavState;
  navItem: NavItem;
  setNavItem: (state: NavItem) => void;
  setLeftNavState: (state: LeftNavState) => void;
}

const NavContext = createContext(defaultValue);

export function NavContextProvider({ children }: React.PropsWithChildren) {
  const [leftNav, setLeftNav] = useState<LeftNavState>(defaultValue.leftNavState);
  const [navItem, setNavItem] = useState<NavItem>(defaultValue.navItem);

  return (
    <NavContext.Provider
      value={{
        leftNavState: leftNav,
        navItem: navItem,
        setNavItem: setNavItem,
        setLeftNavState: setLeftNav,
      }}
    >
      {children}
    </NavContext.Provider>
  );
}

export function useNavContext() {
  return useContext(NavContext);
}
